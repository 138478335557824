import * as React from 'react'
import loadable from '@loadable/component'
import './styles.scss'

const Section = loadable(() => import('/src/components/Section'))
const SectionHeader = loadable(() => import('/src/components/SectionHeader'))
const VideoPlayer = loadable(() => import('/src/components/VideoPlayer'))

const Videos = () => {
    const section = {
        title: 'Meet the parents!',
        description:
            '<p>Hear straight from our customers and find out why they chose to protect their family with life insurance from Protect Line</p>'
    }

    return (
        <Section variant="alt" className="c-videos">
            <SectionHeader data={section} variant="centered" />

            <div className="c-section__content">
                <div className="row">
                    <div className="col-lg-4 c-videos__video">
                        <VideoPlayer
                            data={
                                'https://protect-line-marketing.wistia.com/medias/2khvwu9t64'
                            }
                        />
                    </div>

                    <div className="col-lg-4 c-videos__video">
                        <VideoPlayer
                            data={
                                'https://protect-line-marketing.wistia.com/medias/cg164nqhte'
                            }
                        />
                    </div>

                    <div className="col-lg-4 c-videos__video">
                        <VideoPlayer
                            data={
                                'https://protect-line-marketing.wistia.com/medias/tz5diaoeq7'
                            }
                        />
                    </div>
                </div>
            </div>
        </Section>
    )
}

export default Videos
